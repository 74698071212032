import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { getNewsData } from "../../graph/news";
import { Formik } from "formik";

const LocationModal = ({ locationModal, setLocationModal, distId }) => {
  const [locationData, setLocationData] = useState([]);

  const handleSubmit = (value) => {
    localStorage.setItem("selectedTaluka", value.selectedTaluka);
    // Trigger a custom event
    window.dispatchEvent(new Event("localStorageChanged"));
    setLocationModal(false);
  };
  const getLocationListHandler = async () => {
    let value = {
      // "salt":"436",
      // "sign":"1c59326b84f313f48a0a8ec5f2a019e7",
      method_name: "location_list",
      package_name: "com.prajavarta",
      dist_id: "2",
    };

    try {
      await getNewsData(value).then((res) => {
        setLocationData(res?.data?.ALL_IN_ONE_NEWS?.list);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLocationListHandler();
  }, []);

  return (
    <div>
      <Modal
        open={locationModal}
        onOk={() => setLocationModal(false)}
        onCancel={() => setLocationModal(false)}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <div className="mb-2">
          <h2 className="text-center text-lg font-bold">आपला तालुका निवडा</h2>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{ selectedTaluka: "" }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                {locationData?.map((ele) => (
                  <div
                    key={ele?.loc_id}
                    className="p-2 mb-2 bg-[#F8F9FA] rounded"
                  >
                    <input
                      type="radio"
                      name="selectedTaluka"
                      value={values.selectedTaluka}
                      onChange={() =>
                        setFieldValue("selectedTaluka", ele?.loc_id)
                      }
                      onBlur={handleBlur}
                      id={ele?.loc_id}
                    />
                    <label
                      htmlFor={ele?.loc_id}
                      className="text-base font-normal ml-3"
                    >
                      {ele?.loc_name}
                    </label>
                  </div>
                ))}
                <div className="flex items-center justify-center mt-2">
                  <button
                    type="submit"
                    className="bg-[#5959BD] text-white text-base font-medium px-4 py-2 rounded"
                  >
                    जतन करा
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default LocationModal;
