import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DistrictModal from './modal/DistrictModal'

const ChangeLocationSider = () => {
    const [distModal, setDistModal] = useState(false)
  return (
    <>

    <div className='lg:inline md:inline sm:hidden hidden bg-[#FF7D13] fixed hover:right-0 right-[-110px] transition-all duration-150 delay-150  bottom-[50%]  text-white text-bold z-[52] border-white border-dotted border-2'>
      <div className='lg:p-4 md:p-4 sm:p-2 p-2 lg:pr-5 md:pr-5 sm:pr-3 pr-3 flex items-center '>
        <button className='' onClick={() => setDistModal(true)}>
        <FontAwesomeIcon icon={faMapMarkerAlt} size='lg'/>
            <span className='ml-4 text-lg font-bold'>स्थान बदला</span> 
        </button>
      </div>
    </div>
    <DistrictModal distModal={distModal} setDistModal={setDistModal} />
    </>
  )
}

export default ChangeLocationSider
