import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const OpenInCrome = () => {
  const [showTopbtn, setShowTopBtn] = useState(false);
  const [hidden, setHidden] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 700) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div className={`${hidden ? 'hidden' : 'block'}`}>
      {showTopbtn && (
        <div className="fixed h-20 w-full right-0 left-0 bottom-3 border rounded-sm  bg-state-300 text-white text-bold z-[9999] lg:hidden md:hidden sm:block block">
          <div className="p-2 bg-white text-slate-800">
            <h2 className="font-bold text-xs"> Open Prajavarta in...</h2>
            <div className="flex items-center justify-between w-full my-1">
              <div className="flex items-center gap-2">
                <img
                  className="h-6 w-6"
                  src="https://prajavarta.com/assets/img/favicon.png"
                />
                <p className="font-bold text-xs">Prajavarta App</p>
              </div>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.prajavarta"
                className="rounded-full full p-1 text-white  bg-orange-400  text-xs mr-10"
              >
                Open
              </a>
            </div>
            <div className="w-full h-[1.3px] bg-slate-600 "></div>
            <div className="flex items-center justify-between w-full my-1">
              <div className="flex items-center gap-2">
                <img
                  className="h-6 w-6"
                  src="https://cdn1.iconfinder.com/data/icons/google_jfk_icons_by_carlosjj/512/chrome.png"
                />
                <p className="font-bold text-xs">Crome</p>
              </div>
              <button
                onClick={() => setHidden(true)}
                className="rounded-full full p-1 text-white  bg-orange-400  text-xs mr-10"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenInCrome;
