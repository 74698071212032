import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../assets/images";

const WhatsappShare = () => {
  return (
    <div className="fixed h-8 w-8 right-4 bottom-14  bg-green-700 text-white text-bold rounded-full z-[9999] ">
      <Link
        target="_blank"
        to="https://web.whatsapp.com/send?text=*%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%9C%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%A4%E0%A4%BE%20:%20Pune%20Traffic%20Update%20:%20%E0%A4%AA%E0%A4%82%E0%A4%A4%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A7%E0%A4%BE%E0%A4%A8%20%E0%A4%A8%E0%A4%B0%E0%A5%87%E0%A4%82%E0%A4%A6%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%8B%E0%A4%A6%E0%A5%80%20%E0%A4%AE%E0%A4%82%E0%A4%97%E0%A4%B3%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%87%20%E0%A4%A6%E0%A5%8C%E0%A4%B1%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0,%20%E0%A5%A7%20%E0%A4%91%E0%A4%97%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%B0%E0%A5%8B%E0%A4%9C%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2%20%E0%A4%B5%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A5%81%E0%A4%95%E0%A5%80%E0%A4%A4%20%E0%A4%AC%E0%A4%A6%E0%A4%B2%20-*%20https://prajavarta.com/traffic-changes-in-Pune-on-August-1-due-to-PM-Modi-visits-Pune?id=11516%20%0a%20%20%0a%20*%E0%A5%B2%E0%A4%AA%20%E0%A4%A1%E0%A4%BE%E0%A4%89%E0%A4%A8%E0%A4%B2%E0%A5%8B%E0%A4%A1%20%E0%A4%95%E0%A4%B0%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B8%E0%A4%BE%E0%A4%A0%E0%A5%80%20:-*%20https://bit.ly/3mEyE1V"
      >
        <img className="w-full h-full object-cover" src={IMAGES?.whatsapp1} />
      </Link>
    </div>
  );
};

export default WhatsappShare;
