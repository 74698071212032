import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SocialFlipCard from "../components/SocialFlipCard";
import DetailNewsCard from "../components/DetailNewsCard";
import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import { getNewsData } from "../graph/news";
import RelatedNews from "../components/RelatedNews";

const NewsDetails = () => {
  const [newsDetail, setNewsDetails] = useState();
  const [relatedNews, setRelatedNews] = useState([]);
  const [remainingNews, setremainingNews] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const activeTab = localStorage.getItem("activeTab");
  const activeTabData = JSON.parse(activeTab);
  const newsDetailsHandler = async () => {
    const value = {
      // salt: "699",
      // sign: "3df6d220fa247a377807a130090b831d",
      // package_name: "com.prajavarta",
      method_name: "get_single_news",
      news_id: id,
      user_id: "",
    };
    try {
      await getNewsData(value).then((res) => {
        setNewsDetails(res?.data?.ALL_IN_ONE_NEWS);
        setRelatedNews(res?.data?.ALL_IN_ONE_NEWS[0]?.relative_news);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const newsListHandler = async () => {
    const value = {
      method_name: "get_news",
      page: 1,
      cat_id: activeTabData?.id,
      user_id: "",
    };
    try {
      getNewsData(value).then((res) => {
        setremainingNews(res?.data?.ALL_IN_ONE_NEWS);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    newsDetailsHandler();
    newsListHandler();
  }, [pathname]);
  return (
    <>
      <div className="lg:px-4 md:px-4 sm:px-0 px-0 ">
        <AppHeader headerActive={true} />

        <section className="mt-3 relative w-full md:px-4 lg:px-4 sm:px-0 px-0">
          <div className="lg:mx-28 md:mx-12 sm:mx-1 mx-1">
            <div className="grid grid-cols-12 gap-2">
              <div className="lg:col-span-8 md:col-span-8 sm:col-span-12 col-span-12">
                {newsDetail?.map((ele) => (
                  <div>
                    <DetailNewsCard data={ele} />

                    <div className=" block ">
                      {remainingNews?.map((item) => (
                        <RelatedNews data={item} />
                      ))}
                    </div>
                  </div>
                ))}

                {/* <DetailNewsCard /> */}
              </div>

              <div className="lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12  ">
                <div className="sticky lg:top-[52px] ">
                  <SocialFlipCard
                    relatednewsData={relatedNews}
                    relatednews={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <AppFooter />  */}
    </>
  );
};

export default NewsDetails;
