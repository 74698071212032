import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import categorySlice from "./Slices/categorySlice";

const store = configureStore({
  reducer: {
    category:categorySlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
