import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import NotificationComponent from "./NotificationComponent";
import WhatsappShare from "./WhatsappShare";
import TopToScroll from "./TopToScroll";
import ChangeLocationSider from "./ChangeLocationSider";
import OpenInCrome from "./OpenInCrome";

const PageLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  console.log("ismobile", isMobile);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="relative  h-full w-full">
      <ChangeLocationSider />
      <OpenInCrome />
      <TopToScroll />
      <WhatsappShare />
      <NotificationComponent />
      {/* <div className='px-4 bg-white'>
      <AppHeader />
      </div> */}
      <div>
        <Outlet />
      </div>
      <AppFooter />
    </div>
  );
};

export default PageLayout;
