import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "selectTableValue",
  initialState: {
    category: {}
  },
  reducers: {
    AddActegory: (state, action) => {
      state.category = action.payload;
    },
  },
});

export default categorySlice.reducer;
export const { AddActegory } = categorySlice.actions;

export const categoryData = (state) => state.category.category;
