import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getNewsAds } from "../graph/news";
import { IMAGES } from "../assets/images";

const DetailNewsCard = ({ data, relatedNews }) => {
  // const insertAds = (content) => {
  //   const adHTML1 = `<span id="AADIV30"></span>`;
  //   const adHTML2 = `<span id="AADIV31"></span>`;
  //   const adHTML3 = `<span id="AADIV32"></span>`;

  //   // Split the content at suitable positions
  //   const contentArray = content.split("");

  //   console.log("content array length => ", contentArray.length)

  //   const insertionIndex1 = Math.floor(contentArray.length / 2);

  //   contentArray.splice(insertionIndex1, 0, adHTML1);

  //   const insertionIndex2 = Math.floor((2 * contentArray.length) / 3);
  //   contentArray.splice(insertionIndex2, 0, adHTML2);

  //   const insertionIndex3 = Math.floor(contentArray.length / 4);
  //   contentArray.splice(insertionIndex3, 0, adHTML3);

  //   // Join the content back together
  //   return contentArray.join("");
  // };

  const insertAds = (content) => {
    const adHTML1 = '<div id="AADIV30"></div>';
    const adHTML2 = '<div id="AADIV31"></div>';
    const adHTML3 = '<div id="AADIV32"></div>';

    // Split the content into paragraphs
    const paragraphs = content.split("</p>");

    if (paragraphs.length >= 3) {
      paragraphs.splice(1, 0, adHTML1);
      paragraphs.splice(1, 0, adHTML2);
      paragraphs.splice(paragraphs.length, 0, adHTML3);

      const modifiedContent = paragraphs.join("</p>");
      return modifiedContent;
    } else {
      return content;
    }
  };

  const newsAdsHandler = async () => {
    try {
      await getNewsAds("AADIV31", 31).then((res) => {});
    } catch (err) {
      console.error(err);
    }
  };

  // const setHTML = (e, t, n) => {
  //   if (typeof setHTML !== "function") {
  //     window.setHTML = function (e, t, n) {
  //       if (n) {
  //         e.innerHTML = "";
  //       }
  //       var i = document.createElement("div");
  //       if (((i.innerHTML = t), i.children.length !== 0)) {
  //         for (var r = 0; r < i.children.length; r++) {
  //           var a = i.children[r];
  //           var l = document.createElement(a.nodeName);
  //           for (var d = 0; d < a.attributes.length; d++) {
  //             l.setAttribute(
  //               a.attributes[d].nodeName,
  //               a.attributes[d].nodeValue
  //             );
  //           }
  //           if (a.children.length === 0) {
  //             switch (a.nodeName) {
  //               case "SCRIPT":
  //                 if (a.text) {
  //                   l.text = a.text;
  //                 }
  //                 break;
  //               default:
  //                 if (a.innerHTML) {
  //                   l.innerHTML = a.innerHTML;
  //                 }
  //             }
  //           } else {
  //             setHTML(l, a.innerHTML, false);
  //           }
  //           e.appendChild(l);
  //         }
  //       } else {
  //         e.innerHTML = t;
  //       }
  //     };
  //   }
  // };

  // useEffect(() => {
  //   // newsAdsHandler();
  // }, []);

  // useEffect(() => {
  //   // Array of scripts to be added
  //   const scripts = [
  //     {
  //       src: "https://prajavarta.com/Admanager/ser.php?t=AADIV30&f=30",
  //       async: true,
  //     },
  //     {
  //       src: "https://prajavarta.com/Admanager/ser.php?t=AADIV31&f=31",
  //       async: true,
  //     },
  //     {
  //       src: "https://prajavarta.com/Admanager/ser.php?t=AADIV32&f=32",
  //       async: true,
  //     },
  //   ];

  //   // Loop through the array and add scripts
  //   scripts.forEach((scriptInfo) => {
  //     const script = document.createElement("script");

  //     script.src = scriptInfo.src;
  //     script.async = scriptInfo.async;
  //     document.body.appendChild(script);

  //     return () => {
  //       // Clean up the script when the component is unmounted
  //       document.body.removeChild(script);
  //     };
  //   });
  // }, []);

  useEffect(() => {
    const scripts = [
      {
        src: "https://prajavarta.com/Admanager/ser.php?t=AADIV30&f=30",
        async: true,
      },
      {
        src: "https://prajavarta.com/Admanager/ser.php?t=AADIV31&f=31",
        async: true,
      },
      {
        src: "https://prajavarta.com/Admanager/ser.php?t=AADIV32&f=32",
        async: true,
      },
    ];

    try {
      scripts.forEach((scriptInfo) => {
        const script = document.createElement("script");
        script.src = scriptInfo.src;
        script.async = scriptInfo.async;

        document.body.appendChild(script);
      });
    } catch (err) {
      throw err;
    }
    return () => {
      // Clean up scripts when the component is unmounted

      try {
        scripts.forEach((scriptInfo) => {
          const scriptElements = document.querySelectorAll(
            `[src="${scriptInfo.src}"]`
          );
          scriptElements.forEach((scriptElement) => {
            if (scriptElement.parentNode) {
              scriptElement.parentNode.removeChild(scriptElement);
            }
          });
        });
      } catch (err) {
        throw err;
      }
    };
  }, []);

  return (
    <div className="mt-3 relative w-full bg-[#F8F9FA] mb-2 p-4 pb-0">
      <div className="mb-4 mx-auto h-auto w-full">
        <h1 className="mb-2 text-2xl font-bold">{data?.news_heading}</h1>
        <p className="mb-0">
          <small>तारीख : {data?.news_date}</small>
          <small className="float-right">
            श्रेणी :
            <span className="bg-[#ff7d13] text-white px-4 ml-1 py-1">
              {data?.category_name}
            </span>
          </small>
        </p>
      </div>
      {/* 
              <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-4584194768057025"
        data-ad-slot="Cjngy82Y2XW5pk_DW8gAkXxrP7ClhF5jv4XBzSv0VU4"
        data-ad-format="auto" // or other ad formats
      ></ins> */}

      <div className="w-full relative news-img">
        <div className="text-center mb-4 float-left w-full relative">
          <div className="absolute bottom-8 right-1 ">
            <Link
              to="https://web.whatsapp.com/send?text=*%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%9C%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%A4%E0%A4%BE%20:%20Pune%20Traffic%20Update%20:%20%E0%A4%AA%E0%A4%82%E0%A4%A4%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A7%E0%A4%BE%E0%A4%A8%20%E0%A4%A8%E0%A4%B0%E0%A5%87%E0%A4%82%E0%A4%A6%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%8B%E0%A4%A6%E0%A5%80%20%E0%A4%AE%E0%A4%82%E0%A4%97%E0%A4%B3%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%87%20%E0%A4%A6%E0%A5%8C%E0%A4%B1%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0,%20%E0%A5%A7%20%E0%A4%91%E0%A4%97%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%B0%E0%A5%8B%E0%A4%9C%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2%20%E0%A4%B5%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A5%81%E0%A4%95%E0%A5%80%E0%A4%A4%20%E0%A4%AC%E0%A4%A6%E0%A4%B2%20-*%20https://prajavarta.com/traffic-changes-in-Pune-on-August-1-due-to-PM-Modi-visits-Pune?id=11516%20%0a%20%20%0a%20*%E0%A5%B2%E0%A4%AA%20%E0%A4%A1%E0%A4%BE%E0%A4%89%E0%A4%A8%E0%A4%B2%E0%A5%8B%E0%A4%A1%20%E0%A4%95%E0%A4%B0%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B8%E0%A4%BE%E0%A4%A0%E0%A5%80%20:-*%20https://bit.ly/3mEyE1V"
              target="_blank"
            >
              <img className="h-7 float-right " src={IMAGES?.whatsapp2} />
            </Link>
          </div>
          <img
            className="h-full w-full object-cover  rounded"
            src={
              data?.news_featured_image
                ? data?.news_featured_image
                : IMAGES?.prevImg
            }
          />
        </div>
      </div>

      <div className="news-text">
        <div className="items-justify">
          {relatedNews ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.news_description,
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: insertAds(data?.news_description),
              }}
            />
          )}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: data?.news_description,
            }}
          /> */}

          {/* <div id="AADIV30"></div>
       <div  id="AADIV31"></div>
       <div  id="AADIV32"></div> */}

          {/* <p className="mb-2">
                    <strong>लोणावळा (Lonavala) :</strong>
                    मुंबई-पुणे द्रुतगती महामार्गावर काचेची वाहतूक करणाऱ्या
                    ट्रकने समोरील ट्रकला पाठीमागून जोरदार धडक दिल्याने भीषण
                    अपघात झाला. गुरुवारी (दि. ३) पहाटे साडेपाच वाजण्याच्या
                    सुमारास हा अपघात झाला. या अपघातात एकाचा मृत्यू झाला असून
                    दोनजण गंभीर जखमी आहेत.
                  </p>
                  <p className="mb-2">
                    मिळालेल्या माहितीनुसार, मुंबई पुणे द्रुतगती महामार्गावर
                    बोरघाट पोलीस चौकीजवळ काचेची वाहतूक करणाऱ्या ट्रकला (MH 09 CU
                    6168) अपघात झाला. चालकाचे वाहनावरील नियंत्रण सुटल्याने
                    त्याने समोरील ट्रकला (KA 32 D 3293) धडक दिली. मुंबई लेनवर
                    झालेल्या या अपघातात एकाचा मृत्यू झाला असून दोनजण गंभीर जखमी
                    आहेत. जखमींना आयआरबी पेट्रोलिंग, देवदूत यंत्रणा आणि बोरघाट
                    पोलिसांच्या मदतीने बाहेर काढून लोकमान्य हॉस्पिटलच्या
                    ॲम्बुलन्सने पवना ट्रामा केअर हॉस्पिटल येथे पुढील उपचारार्थ
                    रवाना करण्यात आले आहे.
                  </p>
                  <p className="mb-2">
                    दरम्यान, या अपघातामुळे मुंबई लेनवर काचा पसरल्याने वाहतूक
                    पूर्णपणे ठप्प झाली आहे. हलकी वाहने खोपोली शहरातून जुन्या
                    राष्ट्रीय मार्गावर वळवण्यात आली आहे. क्रेनच्या साहाय्याने
                    अपघातग्रस्त ट्रक बाजूला करण्याचे काम सुरू आहे. तर वरसोली
                    टोलनाका व वलवण गावाजवळ अवजड वाहने थांबविल्यामुळे जुन्या
                    मुंबई-पुणे महामार्गावर देखील वाहतूककोंडी झाली आहे.
                  </p> */}
        </div>
      </div>

      <div className="">
        <p className="mb-0 ">
          <small> विषय :</small>
        </p>
      </div>

      <div className="mt-4 relative w-full">
        <div className="flex items-start justify-end w-fit ml-auto  p-4">
          {/* <Link to="/news/whatsapp">Whatsapp</Link> */}

          <Link
            className=""
            to="https://web.whatsapp.com/send?text=*%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%9C%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%A4%E0%A4%BE%20:%20Pune%20Traffic%20Update%20:%20%E0%A4%AA%E0%A4%82%E0%A4%A4%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A7%E0%A4%BE%E0%A4%A8%20%E0%A4%A8%E0%A4%B0%E0%A5%87%E0%A4%82%E0%A4%A6%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%8B%E0%A4%A6%E0%A5%80%20%E0%A4%AE%E0%A4%82%E0%A4%97%E0%A4%B3%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%87%20%E0%A4%A6%E0%A5%8C%E0%A4%B1%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0,%20%E0%A5%A7%20%E0%A4%91%E0%A4%97%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%B0%E0%A5%8B%E0%A4%9C%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2%20%E0%A4%B5%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A5%81%E0%A4%95%E0%A5%80%E0%A4%A4%20%E0%A4%AC%E0%A4%A6%E0%A4%B2%20-*%20https://prajavarta.com/traffic-changes-in-Pune-on-August-1-due-to-PM-Modi-visits-Pune?id=11516%20%0a%20%20%0a%20*%E0%A5%B2%E0%A4%AA%20%E0%A4%A1%E0%A4%BE%E0%A4%89%E0%A4%A8%E0%A4%B2%E0%A5%8B%E0%A4%A1%20%E0%A4%95%E0%A4%B0%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B8%E0%A4%BE%E0%A4%A0%E0%A5%80%20:-*%20https://bit.ly/3mEyE1V"
            target="_blank"
          >
            <img
              className="h-7 text-right"
              // src="https://prajavarta.com/assets/img/whatsapp.png"
              src={IMAGES?.whatsapp1}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DetailNewsCard;
