import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const NotificationComponent = () => {
  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // console.log('Notification permission granted.');
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };
  return (
    <div className="h-8 w-8 rounded-full fixed left-4 bottom-4 z-[9999] bg-blue-700 text-white text-bold flex items-center justify-center ">
      <button onClick={requestNotificationPermission}>
        <FontAwesomeIcon icon={faBell} size="lg" />
      </button>
    </div>
  );
};

export default NotificationComponent;
