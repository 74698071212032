import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const ScrollToTopPageLink = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top when the URL changes
    scroll.scrollToTop({
      duration: 500, // Duration of the smooth scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for the scroll animation
    });
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTopPageLink;

