import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNewsData } from "../graph/news";
import { IMAGES } from "../assets/images";

const ImgNewsSlider = () => {
  const [treingNewsData, setTrendingNewsData] = useState([]);
  const customeSlider = useRef();

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const [sliderSettings, setSliderSettings] = useState({
    dots: false,
    infinite: true,
    arrow: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  });

  const trendingNewsHandler = async () => {
    const values = {
      method_name: "get_home",
      user_id: "",
    };
    try {
      await getNewsData(values).then((res) => {
        setTrendingNewsData(res?.data?.ALL_IN_ONE_NEWS?.trending_news);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    trendingNewsHandler();
  }, []);

  return (
    <section className="mt-3 overflow-hidden w-full relative mb-2">
      <div className="relative">
        <div className="absolute top-0 z-50 right-2 m-1 lg:block md:block hidden">
          <button
            onClick={() => gotoNext()}
            className=" bg-[#000099] text-white hover:opacity-100 opacity-50  h-10 w-10 rounded text-center align-middle relative "
          >
            <FontAwesomeIcon icon={faChevronLeft} size="lg" />
          </button>
          <button
            onClick={() => gotoPrev()}
            className=" bg-[#000099] text-white hover:opacity-100 opacity-50  h-10 w-10 rounded text-center align-middle relative ml-2"
          >
            <FontAwesomeIcon icon={faChevronRight} size="lg" />
          </button>
        </div>

        <div className="w-full">
          <Slider {...sliderSettings} ref={customeSlider}>
            {/* <div className="rounded-lg flex h-full items-center">
            <div className="group relative w-min-80 h-96 ">
              <img
                className="w-full h-full object-cover rounded-lg"
                src="https://placehold.co/600x400@3x.png"
                alt="Card"
              />
              <div className="absolute top-0 right-0 group-hover:block transition-transform duration-300 transform-gpu p-4">
                <p className="right-0">
                  <i className="fas fa-heart"></i>
                </p>
              </div>
              <div className="absolute bottom-0 left-0 group-hover:block transition-transform duration-300 transform-gpu p-4">
                <div>
                  <h2 className="">BANKSY Crayon for Closure, Kolodny</h2>
                  <span className="text-xs">Banksy</span>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-lg flex h-full items-center">
            <div className="group relative w-min-80 h-96 overflow-hidden">
              <img
                className="w-full h-full object-cover rounded-lg"
                src="https://placehold.co/600x400@3x.png"
                alt="Card"
              />
              <div className="absolute top-0 right-0 group-hover:block transition-transform duration-300 transform-gpu p-4">
                <p className="right-0">
                  <i className="fas fa-heart"></i>
                </p>
              </div>
              <div className="absolute bottom-0 left-0 group-hover:block transition-transform duration-300 transform-gpu p-4">
                <div>
                  <h2 className="">BANKSY Crayon for Closure, Kolodny</h2>
                  <span className="text-xs">Banksy</span>
                </div>
              </div>
            </div>
          </div> */}

            {treingNewsData?.map((ele, idx) => (
              <div
                key={idx}
                className="relative mx-auto overflow-hidden px-2 w-full lg:h-96 md:h-80 rounded-lg h-52 z-20 "
              >
                <Link
                  target="_blank"
                  to={`https://web.whatsapp.com/send?text=*प्रजावार्ता : ${ele?.news_heading} -* http://prajavarta.com/${ele?.news_url}?id=${ele?.id} %0a  %0a *अ‍ॅप डाउनलोड करण्यासाठी :- *https://bit.ly/3mEyE1V`}
                  className="mt-2 absolute bottom-6 right-6 cursor-pointer z-50 w-fit"
                >
                  <span className="text-green-500 mr-2">
                    <img
                      className="h-6 float-right rounded-lg"
                      src={IMAGES?.whatsapp1}
                    />
                  </span>
                </Link>
                <img
                  src={ele?.news_featured_image}
                  alt="Background"
                  className="w-full h-full object-cover"
                />

                <div className="absolute mx-2 bottom-0 left-0 right-0 opacity-80 bg-gradient-to-t from-black via-black to-transparent h-1/2"></div>

                <Link
                  to={`/${ele?.news_url}/${ele?.id}`}
                  className="absolute w-full bottom-0 left-0 right-0 pl-6 pr-12 pb-6"
                >
                  <p className="text-gray-300 text-base font-medium">
                    {ele?.news_heading}
                  </p>
                </Link>
              </div>
            ))}

            {/* <div className="relative mx-4 w-full h-96 z-20">
              <Link
                target="_blank"
                to="https://web.whatsapp.com/send?text=*%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%9C%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%A4%E0%A4%BE%20:%20Pune%20Traffic%20Update%20:%20%E0%A4%AA%E0%A4%82%E0%A4%A4%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A7%E0%A4%BE%E0%A4%A8%20%E0%A4%A8%E0%A4%B0%E0%A5%87%E0%A4%82%E0%A4%A6%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%8B%E0%A4%A6%E0%A5%80%20%E0%A4%AE%E0%A4%82%E0%A4%97%E0%A4%B3%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%87%20%E0%A4%A6%E0%A5%8C%E0%A4%B1%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0,%20%E0%A5%A7%20%E0%A4%91%E0%A4%97%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%B0%E0%A5%8B%E0%A4%9C%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2%20%E0%A4%B5%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A5%81%E0%A4%95%E0%A5%80%E0%A4%A4%20%E0%A4%AC%E0%A4%A6%E0%A4%B2%20-*%20https://prajavarta.com/traffic-changes-in-Pune-on-August-1-due-to-PM-Modi-visits-Pune?id=11516%20%0a%20%20%0a%20*%E0%A5%B2%E0%A4%AA%20%E0%A4%A1%E0%A4%BE%E0%A4%89%E0%A4%A8%E0%A4%B2%E0%A5%8B%E0%A4%A1%20%E0%A4%95%E0%A4%B0%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B8%E0%A4%BE%E0%A4%A0%E0%A5%80%20:-*%20https://bit.ly/3mEyE1V"
                className="mt-2 absolute bottom-6 right-6 cursor-pointer z-50 w-fit"
              >
                <span className="text-green-500 mr-2">
                  <img
                    className="h-6 float-right "
                    src="https://prajavarta.com/assets/img/whatsapp.png"
                  />
                </span>
              </Link>
              <img
                src="https://prajavarta.com/Admin/images/7e0760b8-4ea8-4ecb-9459-411ba61df760-1690962143.jpeg"
                alt="Background"
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 opacity-80 bg-gradient-to-t from-black via-black to-transparent h-1/2"></div>

              <Link
                to="/news/link"
                target="_blank"
                className="absolute w-full bottom-0 left-0 right-0 pl-6 pr-12 pb-6"
              >
                <p className="text-gray-300 text-base font-medium">
                  {" "}
                  Terrorism In Kamshet : 'अल-सफा' अतिरेकी गटाकडून कामशेत शहरात
                  'रतलाम' मॉड्युलसाठी बेस कॅम्पसाठी चाचपणी; एटीएसच्या तपासात
                  धक्कादायक खुलासा
                </p>
              </Link>
            </div> */}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ImgNewsSlider;
