import { Modal } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import LocationModal from "./LocationModal";

const DistrictModal = ({ distModal, setDistModal }) => {
  const [locationModal, setLocationModal] = useState(false);
  const handleSubmit = () => {
    setLocationModal(true);
    setDistModal(false);
  };
  return (
    <div>
      <Modal
        open={distModal}
        onOk={() => setDistModal(false)}
        onCancel={() => setDistModal(false)}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <div className="mb-2">
          <h2 className="text-center text-lg font-bold">आपला जिल्हा निवडा</h2>
        </div>

        {/* <Formik
          enableReinitialize={true}
          initialValues={{ district: "" }}
          onSubmit={handleSubmit}
        ></Formik> */}

        <div className="p-2 bg-[#F8F9FA] rounded">
          <input
            type="radio"
            defaultChecked
            name="dist"
            value="Pune"
            id="pune"
          />
          <label htmlFor="pune" className="text-base font-medium ml-3">
            Pune
          </label>
        </div>
        <div className="flex items-center justify-center mt-2">
          <button
            type="submit"
            className="bg-[#000099] text-white text-base font-medium px-4 py-2 rounded"
            onClick={() => handleSubmit()}
          >
            जतन करा
          </button>
        </div>
      </Modal>
      <LocationModal
        locationModal={locationModal}
        setLocationModal={setLocationModal}
      />
    </div>
  );
};

export default DistrictModal;
