import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import PageLayout from "./Layout";
import Listing from "../pages/Listing";
import NewsDetails from "../pages/NewsDetails";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiePolicy from "../pages/CookiePolicy";
import Home from "../pages/Home";
import ScrollToTopPageLink from "./ScrollToTopPageLink";

const PageRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/" element={<Listing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/news" element={<Listing />} />
          <Route path="/:name/:id" element={<NewsDetails />} />
        </Route>
        <Route path="/terms_and_conditions" element={<TermsAndCondition />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/cookie_policy" element={<CookiePolicy />} />
      </Routes>
      <ScrollToTopPageLink />
    </>
  );
};

export default PageRouter;
