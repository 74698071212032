export const IMAGES = {
  prevImg: require("./preimg.png"),
  sliderImg: require("./app-img.png"),
  favicon: require("./favicon.png"),
  cromeLogo: require("./crome.png"),
  logoLg: require("./logo-wh.png"),
  logosm: require("./logo.png"),
  whatsapp1: require("./whatsapp.png"),
  whatsapp2: require("./whatsapp2.png"),
};
