import "../src/assets/css/style.css";
import PageRouter from "./components/Router";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter basename="/">
      <PageRouter />
    </BrowserRouter>
  );
}

export default App;
