import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const TopToScroll = () => {
  const [showTopbtn, setShowTopBtn] = useState(false);
  const [topScrollHidden, setTopScrollHidden] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        // setShowTopBtn(false);
      }

      if (window.scrollY > 700) {
        setTopScrollHidden(true);
      } else {
        setTopScrollHidden(false);
      }
    });
  }, []);

  return (
    <>
      {showTopbtn && (
        <div className={`${!topScrollHidden ? "hidden" : "inline"}`}>
          <div
            className={` fixed lg:hidden md:hidden top-[50%]  bg-[#B90000] text-white rounded-r-full left-0 z-[9999] flex items-center justify-center`}
          >
            <button className="p-1">
              <Link
                className="text-sm font-bold"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=com.prajavarta"
              >
                अ‍ॅप मधे वाचा
              </Link>
            </button>
          </div>

          <div className="fixed bottom-4 h-8 w-8 bg-blue-700 text-white rounded-full right-4 z-[9999] flex items-center justify-center">
            <button className="" onClick={goToTop}>
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TopToScroll;
