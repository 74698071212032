import React, { useEffect, useRef, useState } from "react";
import LocalNewsCard from "../components/LocalNewsCard";
import ImgNewsSlider from "../components/ImgNewsSlider";
import SocialFlipCard from "../components/SocialFlipCard";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { getNewsData } from "../graph/news";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { categoryData } from "../Store/Slices/categorySlice";
import TrendingNews from "../components/TrendingNews";
import LocalNews from "../components/LocalNews";
import LocalNewsMobile from "../components/LocalNewsMobile";
import InfiniteScrollComponent from "../components/InfiniteScrollComponent";
import { IMAGES } from "../assets/images";

const Listing = () => {
  const getCategory = useSelector((state) => state.category.category);
  const [cateName, setCateName] = useState(getCategory?.catName);
  const [currenctCategory, setCurrenctCategory] = useState(
    localStorage.getItem("activeTab") || "fresh"
  );
  const [listingPage, setListingPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  let categoryId = localStorage.getItem("activeTab");

  const cat_data = JSON.parse(categoryId) || {
    id: "fresh",
    catName: "ताज्या बातम्या",
  };
  const [prevCat, setPrevCat] = useState(JSON.parse(categoryId));
  const { state } = useLocation();
  const [categoryNews, setCategoryNews] = useState([]);

  const navigateToDetailPage = (url, id) => {
    navigate(`/${url}/${id}`);
  };
  // to={`/${ele?.news_url}/${ele?.id}`}

  const newsListingHandler = async (DataId) => {
    try {
      if (cat_data?.id === "fresh") {
        const value = {
          method_name: "get_category_latest",
          page: listingPage,
          user_id: "",
        };
        try {
          const newData = await getNewsData(value).then((res) => {
            return res?.data?.ALL_IN_ONE_NEWS;
            // console.log("latest News => ", res?.data?.ALL_IN_ONE_NEWS);
            // let newdata = res?.data?.ALL_IN_ONE_NEWS;
            // setCategoryNews(res?.data?.ALL_IN_ONE_NEWS);
            // console?.log("news listing in api => ",res?.data?.ALL_IN_ONE_NEWS)
            // setCategoryNews((prevItems) => [...prevItems, ...res?.data?.ALL_IN_ONE_NEWS]);
          });
          // setCategoryNews([...categoryNews, ...newData]);
          setCategoryNews(newData);
        } catch (err) {
          throw err;
        }
      } else if (DataId) {
        const values = {
          page: listingPage,
          cat_id: DataId,
          method_name: "get_news",
          user_id: "",
        };

        try {
          const newData = await getNewsData(values).then((res) => {
            return res?.data?.ALL_IN_ONE_NEWS;
            // console.log("all news data => ", res?.data);
            // let newdata = res?.data?.ALL_IN_ONE_NEWS;
            // setCategoryNews(res?.data?.ALL_IN_ONE_NEWS);
            // console?.log("news listing in api => ",res?.data?.ALL_IN_ONE_NEWS)
            // setCategoryNews((prevItems) => [...prevItems, ...res?.data?.ALL_IN_ONE_NEWS]);
          });

          setCategoryNews([...categoryNews, ...newData]);
          // setCategoryNews(newData);
        } catch (err) {
          throw err;
        }
      }

      setTimeout(async () => {
        // Append new data to the existing items
        // setCategoryNews([...categoryNews, ...newData]);
        // setCategoryNews(newData);

        // In this example, we simulate reaching the end of data after 30 items
        if (categoryNews.length >= 40) {
          setHasMore(false);
        }

        // increaase page count

        setListingPage(listingPage + 1);
      }, 1000);
    } catch (err) {
      console.error(err);
    }
    // setListingPage((prev) => prev + 1)
  };

  const newsListingHandlerNotMatch = async (DataId) => {
    setListingPage(1);
    try {
      if (cat_data?.id === "fresh") {
        const value = {
          method_name: "get_category_latest",
          page: listingPage,
          user_id: "",
        };
        try {
          const newData = await getNewsData(value).then((res) => {
            return res?.data?.ALL_IN_ONE_NEWS;
            // console.log("latest News => ", res?.data?.ALL_IN_ONE_NEWS);
            // let newdata = res?.data?.ALL_IN_ONE_NEWS;
            // setCategoryNews(res?.data?.ALL_IN_ONE_NEWS);
            // console?.log("news listing in api => ",res?.data?.ALL_IN_ONE_NEWS)
            // setCategoryNews((prevItems) => [...prevItems, ...res?.data?.ALL_IN_ONE_NEWS]);
          });
          // setCategoryNews([...categoryNews, ...newData]);
          setCategoryNews(newData);
        } catch (err) {
          throw err;
        }
      } else if (DataId) {
        const values = {
          page: 1,
          cat_id: DataId,
          method_name: "get_news",
          user_id: "",
        };

        try {
          const newData = await getNewsData(values).then((res) => {
            return res?.data?.ALL_IN_ONE_NEWS;
            // console.log("all news data => ", res?.data);
            // let newdata = res?.data?.ALL_IN_ONE_NEWS;
            // setCategoryNews(res?.data?.ALL_IN_ONE_NEWS);
            // console?.log("news listing in api => ",res?.data?.ALL_IN_ONE_NEWS)
            // setCategoryNews((prevItems) => [...prevItems, ...res?.data?.ALL_IN_ONE_NEWS]);
          });

          setCategoryNews(newData);
        } catch (err) {
          throw err;
        }
      }
    } catch (err) {
      console.error(err);
    }
    // setListingPage((prev) => prev + 1)
  };

  useEffect(() => {
    if (categoryNews?.[0]?.category_name === getCategory.catName) {
      newsListingHandler(getCategory.id);
    } else {
      newsListingHandlerNotMatch(getCategory.id);
    }
    newsListingHandler(getCategory.id);
  }, [getCategory?.catName, localStorage.getItem("activeTab")]);

  const fetchData = async () => {
    let newData;
    if (cat_data?.id === "fresh") {
      const values = {
        method_name: "get_category_latest",
        page: listingPage,
        user_id: "",
      };

      newData = await getNewsData(values).then((res) => {
        return res?.data?.ALL_IN_ONE_NEWS;
        // console.log("all news data next 10 => ", res?.data?.ALL_IN_ONE_NEWS);
      });
    } else {
      const values = {
        page: listingPage,
        cat_id: cat_data?.id,
        method_name: "get_news",
        user_id: "",
      };

      newData = await getNewsData(values).then((res) => {
        return res?.data?.ALL_IN_ONE_NEWS;
        // console.log("all news data next 10 => ", res?.data?.ALL_IN_ONE_NEWS);
      });
    }

    setTimeout(async () => {
      setCategoryNews([...categoryNews, ...newData]);
      // setCategoryNews(newData);

      // In this example, we simulate reaching the end of data after 30 items
      if (categoryNews.length >= 40) {
        setHasMore(false);
      }
      // increaase page count
      setListingPage(listingPage + 1);
    }, 1000);
  };

  useEffect(() => {
    // Event listener to detect changes in localStorage
    const handleStorageChange = (e) => {
      if (e.key === "activeTab") {
        // const cat = localStorage.getItem("activeTab")
        // const data = JSON.parse(cat)
        const newCategory = e.newValue;
        // setCurrentCategory(newCategory);
        setCategoryNews([]); // Reset news data
        setListingPage(1); // Reset page count

        // Fetch new data for the new category
        // fetchDataForCategory(newCategory);
        fetchData();
      }
    };

    // Attach the event listener
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [localStorage.getItem("activeTab"), getCategory]);

  // useEffect(() => {
  //   setListingPage(1);
  // }, [localStorage.getItem("activeTab")]);

  return (
    <>
      <div className="lg:px-4 md:px-4 sm:px-0 px-0">
        <AppHeader headerActive={true} />
        <div className="mt-3 md:px-4 lg:px-4 sm:px-0 px-0">
          <TrendingNews />
          <div className=" mb-3">
            <div className="grid  grid-cols-12 gap-2">
              <LocalNews />
              <div className="lg:col-span-5 md:col-span-5 sm:col-span-12 col-span-12 ">
                <ImgNewsSlider />
                <div className="mb-3 lg:mx-4 md:mx-4 sm:mx-2 mx-2">
                  <div className="flex items-center mb-3">
                    <span>
                      <h5 className="text-lg font-bold">
                        <span className=" mr-4 h-4 p-[3px] w-1 bg-[#ff7d13]" />
                        {cat_data?.catName}
                      </h5>
                    </span>
                  </div>
                  <div className="news">
                    {/* <InfiniteScroll
                    dataLength={categoryNews.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                  >
                    {categoryNews?.map((ele) => (
                      <LocalNewsCard
                        categoryNews={true}
                        key={ele?.id}
                        img={ele?.news_featured_thumb}
                        title={ele?.news_heading}
                        link={`/${ele?.news_url}/${ele?.id}`}
                      />
                    ))}
                  </InfiniteScroll> */}

                    <InfiniteScrollComponent
                      activeCategory={cat_data?.id}
                      previousId={cat_data?.id}
                      resetPage={""}
                    />
                  </div>
                </div>
              </div>

              <LocalNewsMobile />

              <div className="col-span-12 mx-auto sm:block lg:hidden md:hidden ">
                <div className="relative flex items-center text-center mx-4 h-full w-72 ">
                  <img
                    className="h-full object-cover w-full"
                    src={IMAGES?.sliderImg}
                  />
                  <div className="absolute bottom-[20px] left-[20px] right-[20px] text-white text-center">
                    <h4 className="mb-2 mt-0 ">प्रजावार्ता</h4>
                    <p>
                      <small>
                        महाराष्ट्राचे पहिले हायपर लोकल न्यूज अँड इंफोटेन्मेन्ट
                        ऍप, बातम्यांसोबत बरेच काही
                      </small>
                    </p>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.prajavarta"
                      target="_blank"
                      className="px-4 py-1 border rounded text-sm mt-2"
                    >
                      Install Now
                    </Link>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12  lg:block md:block lg:sticky lg:top-[52px] md:sticky md:top-[52px] relative h-full">
                <div className="h-full">
                  <SocialFlipCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AppFooter /> */}
    </>
  );
};

export default Listing;
