import React from "react";
import { Link } from "react-router-dom";

const RelatedNews = ({ data }) => {
  return (
    <div className="mt-3 relative w-full bg-[#F8F9FA] mb-2 p-4 pb-0">
      <div className="mb-4 mx-auto h-auto w-full">
        <h1 className="mb-2 lg:text-4xl md:text-4xl sm:text-xl text-xl font-medium">
          {data?.news_heading}
        </h1>
        <p className="mb-0">
          <small>तारीख : {data?.news_date}</small>
          <small className="float-right">
            श्रेणी :
            <span className="bg-[#ff7d13] text-white px-4 ml-1 py-1">
              {data?.category_name}
            </span>
          </small>
        </p>
      </div>
      {/* 
              <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-4584194768057025"
        data-ad-slot="Cjngy82Y2XW5pk_DW8gAkXxrP7ClhF5jv4XBzSv0VU4"
        data-ad-format="auto" // or other ad formats
      ></ins> */}

      <div className="w-full relative news-img">
        <div className="text-center mb-4 float-left w-full relative">
          <div className="absolute bottom-8 right-1 ">
            <Link
              to="https://web.whatsapp.com/send?text=*%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%9C%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%A4%E0%A4%BE%20:%20Pune%20Traffic%20Update%20:%20%E0%A4%AA%E0%A4%82%E0%A4%A4%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A7%E0%A4%BE%E0%A4%A8%20%E0%A4%A8%E0%A4%B0%E0%A5%87%E0%A4%82%E0%A4%A6%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%8B%E0%A4%A6%E0%A5%80%20%E0%A4%AE%E0%A4%82%E0%A4%97%E0%A4%B3%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%87%20%E0%A4%A6%E0%A5%8C%E0%A4%B1%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0,%20%E0%A5%A7%20%E0%A4%91%E0%A4%97%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%B0%E0%A5%8B%E0%A4%9C%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2%20%E0%A4%B5%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A5%81%E0%A4%95%E0%A5%80%E0%A4%A4%20%E0%A4%AC%E0%A4%A6%E0%A4%B2%20-*%20https://prajavarta.com/traffic-changes-in-Pune-on-August-1-due-to-PM-Modi-visits-Pune?id=11516%20%0a%20%20%0a%20*%E0%A5%B2%E0%A4%AA%20%E0%A4%A1%E0%A4%BE%E0%A4%89%E0%A4%A8%E0%A4%B2%E0%A5%8B%E0%A4%A1%20%E0%A4%95%E0%A4%B0%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B8%E0%A4%BE%E0%A4%A0%E0%A5%80%20:-*%20https://bit.ly/3mEyE1V"
              target="_blank"
            >
              <img
                className="h-7 float-right "
                src="https://prajavarta.com/assets/img/whatsapp.png"
              />
            </Link>
          </div>
          <img
            className="h-full w-full object-cover  rounded"
            src={
              data?.news_featured_image
                ? data?.news_featured_image
                : "https://prajavarta.com/assets/img/preimg.png"
            }
          />
        </div>
      </div>

      <div className="news-text">
        <div className="items-justify">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.news_description,
            }}
          />
        </div>
      </div>

      <div className="">
        <p className="mb-0 ">
          <small> विषय :</small>
        </p>
      </div>

      <div className="mt-4 relative w-full">
        <div className="flex items-start justify-end w-fit ml-auto  p-4">
          {/* <Link to="/news/whatsapp">Whatsapp</Link> */}

          <Link
            className=""
            to="https://web.whatsapp.com/send?text=*%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%9C%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%A4%E0%A4%BE%20:%20Pune%20Traffic%20Update%20:%20%E0%A4%AA%E0%A4%82%E0%A4%A4%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A7%E0%A4%BE%E0%A4%A8%20%E0%A4%A8%E0%A4%B0%E0%A5%87%E0%A4%82%E0%A4%A6%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%8B%E0%A4%A6%E0%A5%80%20%E0%A4%AE%E0%A4%82%E0%A4%97%E0%A4%B3%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%87%20%E0%A4%A6%E0%A5%8C%E0%A4%B1%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0,%20%E0%A5%A7%20%E0%A4%91%E0%A4%97%E0%A4%B8%E0%A5%8D%E0%A4%9F%20%E0%A4%B0%E0%A5%8B%E0%A4%9C%E0%A5%80%20%E0%A4%AA%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2%20%E0%A4%B5%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A5%81%E0%A4%95%E0%A5%80%E0%A4%A4%20%E0%A4%AC%E0%A4%A6%E0%A4%B2%20-*%20https://prajavarta.com/traffic-changes-in-Pune-on-August-1-due-to-PM-Modi-visits-Pune?id=11516%20%0a%20%20%0a%20*%E0%A5%B2%E0%A4%AA%20%E0%A4%A1%E0%A4%BE%E0%A4%89%E0%A4%A8%E0%A4%B2%E0%A5%8B%E0%A4%A1%20%E0%A4%95%E0%A4%B0%E0%A4%A3%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B8%E0%A4%BE%E0%A4%A0%E0%A5%80%20:-*%20https://bit.ly/3mEyE1V"
            target="_blank"
          >
            <img
              className="h-7 text-right"
              src="https://prajavarta.com/assets/img/whatsapp.png"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RelatedNews;
